import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ContactForm from '../components/forms/ContactForm'
import Seo from '../components/seo'
import Layout from '../components/layout'
// markup
const IndexPage = () => {
  // const {
  //   page: { seo, content },
  // } = data
  const q = useStaticQuery(query)
  const queryGlobal = q.strapi.data.attributes

  return (
    <Layout className="p-0 m-0 md">
      <Seo seo={{ metaTitle: 'Accueil' }} />
      <main className="text-center flex flex-col justify-center items-center min-h-screen">
        <div className="contact">
          {/* <a className="small-uppercase contact-tel" href={'tel:'+ queryGlobal.tel+''}>tel : {queryGlobal.tel_display} </a> */}
          {/* <a
            className="small-uppercase contact-mail"
            href={
              'mailto:' +
              queryGlobal.mail +
              '?subject=Demande%20de%20renseignements'
            }
          >
            Nous contacter
          </a> */}
          <div className="social-items">
            {queryGlobal.defaultSeo.socials.map((social) => {
              return (
                <a
                  href={social.url}
                  className="item"
                  rel="noreferrer"
                  target="_blank"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: social.SVG,
                    }}
                  ></span>
                  <span className="sr-only">{social.name}</span>
                </a>
              )
            })}
          </div>
        </div>
        <div className="max-w-full mx-auto px-4 text-center">
          <div className="mb-8">Bientôt en ligne</div>
          <h1>
            <img
              src={queryGlobal.logo.data.attributes.localFile.url}
              alt={queryGlobal.siteName}
              className="mx-auto"
            />
            <span className="sr-only">{queryGlobal.siteName}</span>
          </h1>
          <div>
            <h2 className="font-redrose text-2xl md:text-4xl mt-5">
              Collectif de création numérique et graphique
            </h2>

            <h3 className="location small-uppercase">
              <span>Lyon</span> <span>Annonay</span>
            </h3>
          </div>
        </div>
        <div className="small-uppercase fixed bottom-3 w-full">
          <br />
          <div className="mt-2 w-full md:flex md:justify-between px-4">
            <div className="mb-3 md:mb-0">
              <a
                className="pt-2 hover:underline"
                href={
                  'mailto:' +
                  queryGlobal.mail +
                  '?subject=Demande%20de%20renseignements'
                }
              >
                {queryGlobal.mail}
              </a>
            </div>
            <div>
              <a
                className="pt-2 hover:underline"
                href={'tel:' + queryGlobal.tel}
              >
                {queryGlobal.tel_display}
              </a>
            </div>
          </div>
        </div>

        {/* <section id="contactUs">
          <header>
            <ContactForm action="https://www.flexyform.com/f/5f9817e127c87a72f33799dba2fbfae2e54ebbc7"></ContactForm>
          </header>
        </section> */}
      </main>
    </Layout>
  )
}

const query = graphql`
  query {
    strapi {
      data {
        attributes {
          mail
          siteName
          siteDescription
          tel
          tel_display
          defaultSeo {
            socials {
              url
              target_blank
              name
              SVG
              icon {
                data {
                  attributes {
                    localFile {
                      svg {
                        content
                      }
                    }
                  }
                }
              }
            }
          }
          logo {
            data {
              attributes {
                alternativeText
                localFile {
                  url
                  svg {
                    content
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
