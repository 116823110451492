import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ seo = {} }) => {
  const { strapi } = useStaticQuery(query)
  const { defaultSeo } = strapi

  const fullSeo = strapi.data.attributes.defaultSeo

  console.log(fullSeo)

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: 'og:title',
          content: fullSeo.metaTitle + ' | ' + strapi.data.attributes.siteName,
        },
        {
          name: 'twitter:title',
          content: fullSeo.metaTitle + ' | ' + strapi.data.attributes.siteName,
        }
      )
    }

    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: 'description',
          content: fullSeo.metaDescription,
        },
        {
          property: 'og:description',
          content: fullSeo.metaDescription,
        },
        {
          name: 'twitter:description',
          content: fullSeo.metaDescription,
        }
      )
    }

    if (fullSeo.shareImage) {
      const imageUrl = fullSeo.shareImage.data.attributes.localFile.url
      tags.push(
        {
          name: 'image',
          content: imageUrl,
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          name: 'twitter:image',
          content: imageUrl,
        }
      )
    }
    if (fullSeo.article) {
      tags.push({
        property: 'og:type',
        content: 'article',
      })
    }
    tags.push({ name: 'twitter:card', content: 'summary_large_image' })

    return tags
  }
  const metaTags = getMetaTags()

  return (
    <Helmet
      title={seo.metaTitle}
      titleTemplate={`%s | ${strapi.data.attributes.siteName}`}
      meta={metaTags}
      link={[
        {
          rel: 'icon',
          href: 'favicon.localFile.url',
        },
        {
          rel: 'preconnect',
          href: 'https://fonts.googleapis.com',
        },
        {
          rel: 'preconnect',
          href: 'https://fonts.gstatic.com',
          crossorigin: true,
        },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Red+Rose:wght@300;400;500;600;700&display=swap',
        },
        {
          rel: 'apple-touch-icon',
          href: './src/assets/favicon/apple-touch-icon.png',
          sizes: '180x180',
        },
        {
          rel: 'icon',
          href: './src/assets/favicon/favicon-32x32.png',
          sizes: '32x32',
          type: 'image/png',
        },
        {
          rel: 'icon',
          href: './src/assets/favicon/favicon-16x16.png',
          sizes: '16x16',
          type: 'image/png',
        },
        {
          rel: 'manifest',
          href: './src/assets/favicon/site.webmanifest',
        },
        {
          rel: 'mask-icon',
          href: './src/assets/favicon/safari-pinned-tab.svg',
          color: '#7faaff',
        },
        {
          name: 'msapplication-TileColor',
          content: '#cbf2d4',
        },
        {
          name: 'theme-color',
          color: '#ffffff',
        },
      ]}
      script={[]}
    ></Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query MyQuery {
    strapi {
      data {
        attributes {
          siteName
          siteDescription
          defaultSeo {
            metaTitle
            metaDescription
            shareImage {
              data {
                attributes {
                  localFile {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Seo
