import React from 'react'

class ContactForm extends React.Component {
  render() {
    return (
      <form action={this.props.action} method="post">
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label for="first-name" className="block ">
                  Nom ou Raison Sociale
                </label>
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  className="mt-1"
                />
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label for="email-address" className="block ">
                  Email address
                </label>
                <input
                  type="mail"
                  name="email-address"
                  id="email-address"
                  autocomplete="email"
                  className="mt-1 "
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button type="submit" className="">
              Envoyer
            </button>
          </div>
        </div>
      </form>
    )
  }
}
export default ContactForm
